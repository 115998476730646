<template>
  <!-- 1.顶部通栏布局 -->
  <AppTopnav />
  <!-- 2.头部布局 -->
  <AppHeader @serach="search" />
  <!-- <AppHeaderSticky /> -->

  <main class="app-body">
    <!-- 二级路由 -->
    <router-view ref="RefChilde" :keywords="keywords"></router-view>
    <Modal />
  </main>
  <AppFooter />
  <!--福利弹窗-->
  <Welfare/>

</template>

<script>
import AppTopnav from '@/components/app-topnav.vue'
import AppHeader from '@/components/app-header.vue'
import AppFooter from '@/components/app-footer.vue'
import Modal from '@/components/modal.vue'
import Welfare from '@/components/welfare.vue'
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// import AppHeaderSticky from '@/components/app-header-sticky.vue'
export default {
  components: { AppTopnav, AppHeader, AppFooter, Modal ,Welfare},
  name: 'xtx-layout',
  setup () {
    const keywords = ref()
    const RefChilde = ref(null)
    const router = useRouter()
    const search = (data) => {
      // console.log(data)
      // keywords.value = data
      // router.push({ path: '/category/search' })
      // console.log(RefChilde.value.$options)
      // RefChilde.value.sonFn();
    }
    return {
      search,
      keywords,
      RefChilde
    }
  }
}
</script>

<style scoped lang='less'>
.app-body {
  min-height: 600px;
}
.floatingWindow {
  width: 98px;
  min-height: 214px;
  background: #ffffff;
  position: fixed;
  right: 20px;
  top: 413px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  z-index: 1000;
  box-shadow: 2px 2px 2px 2px #d1cfcf;
  img {
    width: 16px;
    height: 16px;
  }
  p {
    font-size: 12px;
    color: #808695;
    line-height: 24px;
  }
  .floatingWindow_son {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .soild-bottom {
    border-bottom: 1px solid #f1f1f4;
  }
  .popover {
    width: 175px;
    height: 155px;
    z-index: 100;
    position: absolute;
    top: 0;
    left: -195px;
    background: #ffffff;
    padding: 15px;
  }
  #triangle-right {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-left: 8px solid #ffffff;
    border-bottom: 4px solid transparent;
    position: absolute;
    right: -8px;
    top: 30px;
  }
}
</style>
