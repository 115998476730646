<template>
  <div
    class="welfare"
    :class="isShow?'show':'hidden'"
  >
    <div class="welfare-wrap">
      <div class="wefare-wrap-container">
        <div class="welfare-info" v-html="msg"></div>
        <div class="welfare-btn" @click="getWelfare"></div>
        <div class="welfare-close" @click="close"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter } from "vue-router";
import { useStore } from 'vuex'
import { getAlertContent } from '@/api/FirstPage'
export default {
  name: "Welfare",
  setup () {
    const store = useStore()
    const isShow = ref(true)
    const router = useRouter();
    const msg = ref('');
    const alertContent = async () => {
      const res = await getAlertContent()
      if(res.code == 0) {
        msg.value = res.data
      }
    }
    let user = computed(() => store.state.user)

    onMounted(() => {
      console.log('user',user.value.userinfo)
      //第一次登录之后，点击立即领取之后，再次返回该页面
      if(localStorage.getItem('FirstLoginClick') == 1) {
        isShow.value=false
      }
      // login_count 注册写0，首次登录写1，再次登录累加
      if(Object.keys(user.value.userinfo).length!=0&&user.value.userinfo.login_count > 1 ){
        isShow.value=false
      }
      alertContent()
    })

    const close = () => {
      isShow.value = false
    }

    const getWelfare = () => {
      if(Object.keys(user.value.userinfo).length!=0) {
        if(user.value.userinfo.login_count==1) {
          // 首次登录，点击跳转至福利中心
          isShow.value = false
          localStorage.setItem('FirstLoginClick', 1)
          router.push("/account/wmbeans-chong");
          return;
        }
      }
      router.push("/login");
    }

    return {
      msg,
      isShow,
      close,
      getWelfare
    }
  }
}
</script>
<style scoped lang="less">
.welfare {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  &.show {
    display: none;
  }
  &.hidden {
    display: none;
  }
  .welfare-wrap {
    width: 600px;
    height: 700px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    .wefare-wrap-container {
      background: url("../assets/images/welfare-bg.png") no-repeat;
      background-size: 100% 100%;
      width: 600px;
      height: 611px;
      position: relative;
      .welfare-info {
        width: 314px;
        height: 196px;
        position: absolute;
        left: 50%;
        top: 320px;
        transform: translateX(-50%);
        z-index: 1002;
        line-height: 32px;
        font-size: 20px;
        color: #fef0ca;
        text-shadow: 0 0 5px rgba(0, 0, 0, 50%);
      }
    }
    .welfare-btn {
      background: url("../assets/images/btn_receive.png") no-repeat;
      background-size: 100% 100%;
      width: 243px;
      height: 60px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
      z-index: 1002;
      cursor: pointer;
    }
    .welfare-close {
      background: url("../assets/images/btn_close.png") no-repeat;
      background-size: 100% 100%;
      width: 55px;
      height: 55px;
      position: absolute;
      bottom: -90px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1001;
      cursor: pointer;
    }
  }
}
</style>
