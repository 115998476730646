<template>
  <ul class="app-header-nav">
    <li class="home"><RouterLink to="/">首页</RouterLink></li>
    <li class="home">
      <a href="javascript:;">万谋官网</a>
      <!-- <a href="http://www.wanmoulawfirm.com/" target="_blank">万谋官网</a> -->
    </li>
    <li class="home flzx" @click="goto"><a>福利中心</a></li>
    <li class="home"><RouterLink to="/helpCenter">帮助中心</RouterLink></li>
  </ul>
  <FuliModal v-if="isShow" :isShow="isShow" @click="goLogin"></FuliModal>
</template>

<script>
import { toRefs,ref } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import FuliModal from './fuliModal.vue'
export default {
  name: "AppHeaderNav",
  components: {
    FuliModal
  },
  setup() {
    // 使用仓库实例
    const router = useRouter();
    const { token } = store.state.user;
    const isShow=ref(false);
    const goto = () => {
      if (token) {
        router.push("/account/wmbeans-chong");
      } else {
        isShow.value=true;
        return;
      }
    };

     //关闭弹窗并跳转注册登录页
    const goLogin=()=>{
      router.push("/login");
      isShow.value=false;
      
    }

    return {
      goto,isShow,goLogin
    };
  },
};
</script>

<style scoped lang="less">
.app-header-nav {
  display: flex;
  // justify-content: space-around;
  padding-left: 55px;
  position: relative;
  z-index: 998;
  > li {
    margin-right: 47px;
    // width: 38px;
    text-align: center;
    > a {
      font-size: 16px;
      line-height: 78px;
      height: 68px;
      // border: 1px solid saddlebrown;
      display: flex;
      align-items: center;
    }
    &:hover {
      > a {
        font-weight: 700;
        color: @xtxColor;
        border-bottom: 3px solid @xtxColor;
      }
    }
    &.flzx{
      background: #3857FF;
      padding: 0px 10px;
      > a{
        color:#AEEF00;
      }
      
    }
  }
}

// 子分类
.layer {
  // 如果 layer 自己身上有 open 类名，就显示 layer 层
  &.open {
    height: 132px;
    opacity: 1;
  }
  width: 1240px;
  background-color: #fff;
  position: absolute;
  left: -200px;
  top: 56px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  box-shadow: 0 0 5px #ccc;
  transition: all 0.2s 0.1s;
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 70px;
    align-items: center;
    height: 132px;
    li {
      width: 110px;
      text-align: center;
      img {
        width: 60px;
        height: 60px;
      }
      p {
        padding-top: 10px;
      }
      &:hover {
        p {
          color: @xtxColor;
        }
      }
    }
  }
}
</style>
