<template>
  <div class="fuliModal">
     <p>注册并完成登录后，可在福利中心享受到大量的福利</p>
     <a-button type="primary">确定</a-button>
  </div>
</template>
<script>
export default {
  name: "FuliModal",
}
</script>

<style scoped lang="less">
.fuliModal{
  width:320px;
  height:140px;
  background-color:#fff;
  border-color:#ffccc7;
  position: fixed;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p{
    margin:10px;
  }
}
</style>