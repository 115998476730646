<template>
  <header class="app-header">
    <div class="container">
      <h1 class="logo">
        <img src="@/assets/images/wanmou_logo.png" alt="" />
      </h1>
      <AppHeaderNav />
      <div class="search">
        <div style="display: flex; flex: 1">
          <i class="iconfont icon-search"></i>
          <a-input
            placeholder="请输入关键字"
            v-model:value="keyword"
            style="flex: 1; margin-right: 5px; margin-left: 5px"
          />
        </div>
        <a-button type="primary" @click="search"> 搜索 </a-button>
      </div>
      <div class="cart">
        <router-link to="/order/cart">
          <img src="@/assets/images/Cart@2x.png" alt="" srcset="" />
          <span class="tag">{{ $store.getters["user/validTotal"] }}</span>
        </router-link>
      </div>
      <p class="right" v-if="isshow">
        <router-link to="/Login">
          <span>登录</span>
        </router-link>
        <span>|</span>
        <router-link to="/Login">
          <span>注册</span>
        </router-link>
      </p>
    </div>
  </header>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import AppHeaderNav from "./app-header-nav.vue";
import store from "@/store";
import eventBus from "@/utils/eventBus";
import { useRouter, useRoute } from "vue-router";
export default {
  components: { AppHeaderNav },
  name: "AppHeader",
  setup(props, { emit }) {
    const isshow = ref(true);
    const carLength = ref(0);
    const keyword = ref("");
    const router = useRouter();
    const route = useRoute();
    const isredrect = ref(true);
    const { token } = store.state.user;
    if (token) {
      isshow.value = false;
    }
    const search = () => {
      // emit('serach', keyword.value)
      store.commit("user/setKeyword", keyword.value);
      eventBus.emit("serach");
      router.push({ path: "/category/search" });
      // keyword.value = ''
    };
    onMounted(() => {
      carLength.value = store.state.user.carLength;
      keyword.value = store.state.user.keywords;
    });
    return {
      isshow,
      search,
      carLength,
      keyword,
      isredrect,
    };
  },
};
</script>

<style scoped lang="less">
.app-header {
  width: 100%;
  height: 100px;
  // padding: 0 360px;
  background: #fff;
  .container {
    width: 1178px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .logo {
    width: 151px;
    height: 34px;
    line-height: 25px;
  }
  .navs {
    width: 820px;
    display: flex;
    justify-content: space-around;
    padding-left: 40px;
    li {
      margin-right: 40px;
      width: 38px;
      text-align: center;
      a {
        font-size: 16px;
        line-height: 32px;
        height: 32px;
        display: inline-block;
      }
      &:hover {
        a {
          color: @xtxColor;
          border-bottom: 1px solid @xtxColor;
        }
      }
    }
  }
  .search {
    width: 260px;
    height: 34px;
    border: 1px solid #f1f1f4;
    margin-left: 80px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-search {
      font-size: 18px;
      margin-left: 5px;
    }
    input {
      width: 140px;
      padding-left: 5px;
      color: #666;
    }
  }
  .cart {
    width: 26px;
    height: 26px;
    margin-left: 87px;
    position: relative;
    .tag {
      display: block;
      width: 16px;
      height: 16px;
      background: #ff3c31;
      border-radius: 50%;
      font-size: 7px;
      color: #ffffff;
      text-align: center;
      position: absolute;
      right: -8px;
      top: -8px;
    }
  }
  .right {
    display: flex;
    font-size: 14px;
    color: #515a6e;
    margin-left: 24px;
    span {
      display: block;
    }
  }
}
</style>
